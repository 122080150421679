
const base = {
  //判断是否是iOS WKWebview内核
  isWKWebview: false,
};

/**
 * 当前宿主环境标识
 * 微信小程序：wxxcx
 * 企业微信： wxwork
 * 微信： wx
 * 钉钉: dd
 * 校管家app: xgj
 * 普通浏览器环境: browser
 */
base.env = (function () {
  // navigator.userAgent 属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息
  var ua = window.navigator.userAgent.toLowerCase();

  // 模拟云校app的ua
  // ua = "Mozilla/5.0 (Linux; Android 10; VCE-AL00 Build/HUAWEIVCE-AL00; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/77.0.3865.120 MQQBrowser/6.2 TBS/045613 Mobile Safari/537.36 xgj/cs/1.0"

  if (ua.match(/MicroMessenger/i)) {
    base.isWKWebview = window.__wxjs_is_wkwebview
    if (ua.match(/miniprogram/i)) {
      return 'wxxcx';
    } else if (ua.match(/wxwork/i)) {
      return 'wxwork';
    } else {
      return 'wx';
    }
  } else if (ua.match(/dingtalk/i)) {
    return 'dd';
  } else if (ua.match(/lftapp/i) || ua.match(/xgj/i)) {
    if (ua.match(/wkwebview/i)) {
      base.isWKWebview = true;
    }
    return 'xgj';
  } else {
    return 'browser';
  }
})();



export default base;
