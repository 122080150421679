
import { setConfig, getConfig, config } from './base/config.js'
import OssClass_H5 from './h5/h5-class.js';
import OssClass_XGJ from './xgj/xgj-class.js';


let serial = 1, // 自增id
  doNum = 0,
  queue = [] // 待上传的队列

/* {
  env: 环境，测试还是生产
  getStsParams: 找业务层获取sts的入参的方法，
  file: ,
  content: base64或blob数据
  progress: callback 上传进度回调
} */
class OssClass {
  constructor(opts = {}) {
    this.id = serial++

    this.platform = opts.platform || 'h5'
    this.initClient(opts.options)

    this.promise = new Promise(resolve => {
      this._resolve = resolve
    })

    /* status取值 =>  wait: 等待中，doing：正在上传 stop: 业务层暂停上传  done: 上传完成 cancel: 取消*/
    /* wait： 有可能还未开始，也有可能是被挤占了的暂停 */
    this.status = 'wait'

    // 压入队列
    this.pushQueue()
  }

  initClient(options) {
    // console.log('oss -> ', options);
    if (this.platform == 'h5') {
      this.client = new OssClass_H5(options)
    } else if (this.platform == 'xgj') {
      this.client = new OssClass_XGJ(options)
    }
  }


  // 启动上传
  async ossUpload() {
    doNum++
    this.status = 'doing'
    let uploadResult = await this.client.upload()

    this.status = 'done'
    doNum--
    // console.log('uploadResult => ', uploadResult);
    this._resolve(uploadResult)
    this.removeForQueue()
  }

  // 启动队列消费
  async launch() {
    if (doNum < config.max) {
      let task
      queue.some(item => {
        if (item.status == 'wait') {
          task = item
          return true
        }
      })
      if (task) {
        task.ossUpload()
        task.launch()
      }
    }
  }

  // 压入队列
  // 把上传任务放入上传队列
  pushQueue() {
    queue.push(this)
    this.launch()
  }

  // 从队列中移除
  removeForQueue() {
    let id = this.id, idx
    queue.some((task, index) => {
      if (id === task.id) {
        idx = index
      }
    })
    queue.splice(idx, 1)
    this.launch()
  }

  // 暂停
  async pause() {
    if (this.status == 'wait' || this.status == 'doing') {
      if (this.status = 'doing') {
        doNum--
        this.launch()
      }
      this.client && this.client.pause();
      this.status = 'stop'
    }
  }

  // 恢复
  async resume() {
    if (this.status == 'stop') {
      this.status = 'wait'
      this.launch()
    }
  }

  // 取消
  async cancel() {
    this.pause()
    this.status = 'cancel'
    this.removeForQueue()
  }
}



// 单个上传
async function uploadFile(opt) {
  let task = new OssClass(opt)
  return task.promise
}

// 批量上传
async function uploadFiles(opts) {
  if (!Array.isArray(opts)) {
    opts = [opts];
  }

  let pmArr = opts.map(opt => {
    return uploadFile(opt)
  })

  return Promise.all(pmArr)
}

let oss = {
  OssClass,
  uploadFile,
  uploadFiles,
  setConfig,
  getConfig,
}

export default oss;


