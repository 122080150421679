/**
 *所有原生直接调H5的方法都封装在 window.__xgj__H5ToNative__callback__ 这个对象下
 *现在有如下方法：
 *clickBack => 点击返回键时调用
 *voicePlayEnd => 在原生语音播放完毕时调用
 *voiceRecordEnd => 在原生录音自动停止时调用
 */

import base from '@lib/base/base.js';

let fnNames = [
  "chooseImage",
  'chooseVideo',
  'compressorVideo',
  "uploadImage",
  "uploadVoice",
  "uploadVideo",
  // // 原生提供的取消压缩的方法
  // "cancelCompress",
  // // 原生提供的取消上传的方法
  // "cancelUpload",
];

var xah = {};
var cb = {};

window._xgjapp_ = window.xgjapp

window.__xgj__H5ToNative__callback__ = cb;

if (base.env === 'xgj') {
  const shareName = '__protocal' + Date.now() + '__';

  let idCount = 0;

  function createID() {
    return Date.now() + '$' + idCount++;
  }

  const send = (function () {
    let pool = {};

    window[shareName] = function exec(id) {
      let fn = pool[id];
      //delete pool[id];        //一次性.
      return fn;
    };

    return function (fn) {
      let id = createID();
      pool[id] = fn;
      return [`window.${shareName}('${id}')`, () => delete pool[id]]; //exec(id)(...)
    };
  })();

  const codeMap = {
    '0': 'success',
    '1': 'fail',
    '2': 'cancel',
    '3': 'progress', //查询进度
  };

  function wxApi(appRequest) {
    return function (opt) {
      let delFn = null; //回调函数清理

      function callback(res) {
        // if (!codeMap[res.code]){
        //     throw new Error('错误状态码:' + res.code);
        // }
        let fnName = codeMap[res.code + ''];
        opt[fnName] && opt[fnName](res.data);
        if (res.code != 3) {
          //如果是查询进度，不调用complete
          opt.complete && opt.complete(res);
          delFn();
        }
      }

      if (opt && typeof opt === 'object') {
        [opt.result, delFn] = send(callback);
        window._xgjapp_[appRequest](opt && JSON.stringify(opt));
      } else {
        window._xgjapp_[appRequest]();
      }
    };
  }


  fnNames.forEach(fnname => {
    if (base.isWKWebview === true) {
      if (typeof _xgjapp_ === 'undefined') {
        window._xgjapp_ = {};
      }
      Object.defineProperty(window._xgjapp_, fnname, {
        get: function () {
          return function (params) {
            window.webkit.messageHandlers[`${fnname}_v1`].postMessage(params);
          };
        },
      });
    }

    xah[fnname] = wxApi(fnname);
  });
}

export default xah;
