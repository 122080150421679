
import { config } from './config.js'

let domain = {
  // 'dev': 'https://ztapi-dev.xiaogj.com',
  'dev': 'https://ztapi-test.xiaogj.com',
  'test': 'https://ztapi-test.xiaogj.com',
  'sit': 'https://ztapi-test.xiaogj.com',
  // 'sit': 'https://ztapi-sit.xiaogj.com',
  'prod': 'https://cloudapi.xiaogj.com',
}

// window.domain = domain


/* {
  env: 环境，测试还是生产
  getStsParams: 找业务层获取sts的入参的方法，
  file: ,
  progress: callback 上传进度回调
} */
class Base {
  constructor(opts = {}) {
    this.env = opts.env || config.env

    if (!domain[this.env]) {
      throw Error('请设置正确的环境参数')
    }

    this.file = opts.file
    this.getFileType(opts.file.type)

    this.getStsParams = opts.getStsParams || config.getStsParams(this.file)

    if (typeof opts.progress === 'function') {
      this.progress = opts.progress
    }
    if (typeof opts.success === 'function') {
      this.success = opts.success
    }

    this.client = null
  }

  getFileType(typeString) {
    let typeStr, typeNum;
    if (typeString.indexOf('image') === 0) {
      typeStr = 'image';
      typeNum = '1'
    } else if (typeString.indexOf('video') === 0) {
      typeStr = 'video';
      typeNum = '3'
    } else if (typeString.indexOf('audio') === 0) {
      typeStr = 'audio';
      typeNum = '4'
    } else {
      typeStr = 'file';
      typeNum = '2'
    }

    this.fileType = typeNum
    this.fileTypeStr = typeStr
  }

  async getSts() {
    if (this.sts) { return }
    let stsParams = await this.getStsParams()

    // 处理业务侧自定义参数
    let busInfo = stsParams.businessDto
    for (const key in busInfo) {
      if (Object.hasOwnProperty.call(busInfo, key)) {
        if (busInfo[key] == null) {
          busInfo[key] = ''
        } else {
          busInfo[key] = '' + busInfo[key]
        }
      }
    }

    let ret = await axios({
      baseURL: domain[this.env],
      url: '/file/getStsInfoV2',
      method: 'post',
      data: stsParams,
    })

    this.sts = ret.data.data

    this.customValue = {
      // fileSize: `${this.file.size || ''}`,
      fileName: this.file.name,
      name: this.file.name,
      fileSuffix: this.file.name.split('.').pop(),
      fileType: `${this.fileType}`,
      fileUrl: this.sts.fileUrl,
      fileKey: this.sts.fileKey,
      fileId: this.sts.fileId,
      fileDuration: this.file.duration,
      ...busInfo,
    }

    this.ossFileKey = this.sts.fileKey + `/${this.file.name || Date.now()}`;
  }
}

export default Base;


