
export let config = {
  // 最大同时上传的任务数量
  max: 1,
  env: '',
  getStsParams: function () {
    console.error("请设置上传OSS的policy方法，该方法必须返回一个resolve出policy的promise");
  },
}

export function setConfig(conf) {
  let keys = Object.keys(conf)
  keys.forEach(key => {
    if (typeof conf[key] == 'object') {
      Object.assign(config[key], conf[key])
    } else {
      config[key] = conf[key]
    }
  })
  console.log('配置成功 -> ', conf);
}

export function getConfig() {
  return config
}
