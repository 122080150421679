import Base from '../base/base.js'

/* {
  env: 环境，测试还是生产
  getStsParams: 找业务层获取sts的入参的方法，
  file: ,
  content: base64或blob数据
  progress: callback 上传进度回调
} */
class OssClass_XGJ extends Base {
  constructor(opts = {}) {
    super(opts)

    this.localId = opts.file.localId
    this.config = opts.config || {}
  }

  async fileHandle() {
    let conf_video = this.config.video || {}
    console.log(conf_video);
    if (this.fileTypeStr == 'video' && conf_video.compress) {
      let compressFile = await xgjzt.platform.sdk.compressorVideo({
        path: this.localId,
        quality: conf_video.quality,
        progress: conf_video.progress
      });
      console.log("视频压缩结果 -> ", compressFile);
      this.localId = compressFile.localId
    }
  }

  // 上传普通文件
  async ossClientUpload() {
    await this.fileHandle()
    let uploadParams = {
      localId: this.localId,
      stsInfo: this.sts,
      ossFileKey: this.ossFileKey,
      customValue: this.customValue,
      progress: this.progress
    }
    console.log('APP参数 => ', uploadParams);
    let ret
    if (this.fileTypeStr == 'image') {
      ret = await xgjzt.platform.sdk.uploadImage(uploadParams);
    } else if (this.fileTypeStr == 'video') {
      ret = await xgjzt.platform.sdk.uploadVideo(uploadParams);
    } else if (this.fileTypeStr == 'audio') {
      ret = await xgjzt.platform.sdk.uploadVoice(uploadParams);
    }

    return ret
  }

  // 启动上传
  async upload() {
    this._stop_ = false
    return new Promise(async (resolve, reject) => {
      await this.getSts()
      if (!this._stop_) {
        let uploadResult = await this.ossClientUpload()
        this.success && this.success(uploadResult);
        resolve(uploadResult)
      }
    })
  }

  // 暂停
  async pause() {
    // 标识为暂停状态
    this._stop_ = true
    // this.client && this.client.cancel();
  }

  // 恢复
  async resume() {
    this.upload()
  }
}



export default OssClass_XGJ;


