import base from '@lib/base/base.js'

import xgjsdk from './xgj/xgj.js'


var sdk = {},
    sdkmap = {
        xgj: xgjsdk,
    }

sdk = sdkmap[base.env]


export default { sdk, sdkmap }



