/**
 * 图片压缩工具
 * 同时解决了图片旋转问题
 */

import { getExifJs } from './get-libs.js';

async function getOrientation(file) {
  await getExifJs();
  return new Promise((resolve, reject) => {
    // 之所以加这个定时器，是因为某些图片会导致EXIF.getData方法执行时报错，导致整个流程都卡死
    setTimeout(() => {
      resolve(1);
    }, 1000);
    EXIF.getData(file, function () {
      let Orientation = EXIF.getTag(this, 'Orientation');
      resolve(Orientation);
    });
  });
}

// 压缩前将file转换成img对象
async function readImg(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();
    reader.onload = function (e) {
      img.src = e.target.result;
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsDataURL(file);
    img.onload = function () {
      resolve(img);
    };
    img.onerror = function (e) {
      reject(e);
    };
  });
}

/**
 * 压缩图片
 *@param img 被压缩的img对象
 * @param type 压缩后转换的文件类型
 * @param maxW 触发压缩的图片最大宽度限制
 * @param maxH 触发压缩的图片最大高度限制
 */
async function compressImg({
  img,
  type = 'image/jpeg',
  maxW = '1200',
  maxH = '1200',
  rate = 0.92,
  orientation = 1,
} = opts) {
  return new Promise((resolve, reject) => {
    const { width: originWidth, height: originHeight } = img;
    // // 最大尺寸限制
    // const maxWidth = maxW;
    // const maxHeight = maxH;
    // // 目标尺寸
    // let targetWidth = originWidth;
    // let targetHeight = originHeight;
    // if (originWidth > maxWidth || originHeight > maxHeight) {
    //     if (originWidth / originHeight > 1) {
    //         // 宽图片
    //         targetWidth = maxWidth;
    //         targetHeight = Math.round(maxWidth * (originHeight / originWidth));
    //     } else {
    //         // 高图片
    //         targetHeight = maxHeight;
    //         targetWidth = Math.round(maxHeight * (originWidth / originHeight));
    //     }
    // }

    // 最大尺寸限制
    const maxWidth = maxW;
    // 目标尺寸
    let targetWidth = originWidth;
    let targetHeight = originHeight;
    if (originWidth > maxWidth) {
      // 宽图片
      targetWidth = maxWidth;
      targetHeight = Math.round(maxWidth * (originHeight / originWidth));
    }

    // 创建画布
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // 图片绘制
    // context.clearRect(0, 0, targetWidth, targetHeight);
    if (!window.imgIsRotate) {
      switch (orientation) {
        case 6: // 旋转90度
          canvas.width = targetHeight;
          canvas.height = targetWidth;
          context.rotate(Math.PI / 2);
          // (0,-targetHeight) 从旋转原理图那里获得的起始点
          context.drawImage(img, 0, -targetHeight, targetWidth, targetHeight);
          break;
        case 3: // 旋转180度
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          context.rotate(Math.PI);
          context.drawImage(img, -targetWidth, -targetHeight, targetWidth, targetHeight);
          break;
        case 8: // 旋转-90度
          canvas.width = targetHeight;
          canvas.height = targetWidth;
          context.rotate((3 * Math.PI) / 2);
          context.drawImage(img, -targetWidth, 0, targetWidth, targetHeight);
          break;
        default:
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          context.drawImage(img, 0, 0, targetWidth, targetHeight);
      }
    } else {
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      context.drawImage(img, 0, 0, targetWidth, targetHeight);
    }

    canvas.toBlob(
      function (blob) {
        resolve(blob);
      },
      type || 'image/jpeg',
      // 0.92
      rate
    );
  });
}

// async function compress(file) {
async function compress(opt) {
  let file = opt.file,
    maxW = opt.maxw || 1200,
    rate = opt.rate || 0.92
  const orientation = await getOrientation(file);
  const img = await readImg(file);
  const blob = await compressImg({
    img,
    orientation,
    maxW,
    rate
  });

  return Promise.resolve(blob);
}

export default compress;
