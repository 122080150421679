// 校管家APP环境

import base from '@lib/base/base.js';
import xah from './xgj-sdk.js';

var xgjsdk,
  u = {};

if (base.env === 'xgj') {
  // 选择图片
  u.chooseImage = function(opt = {}) {
    let option = JSON.parse(JSON.stringify(opt));
    option.count = option.max || 9;
    delete option.max;
    return new Promise((resolve, reject) => {
      xah.chooseImage({
        ...option,
        complete: res => {
          res.code === 0 ? resolve(res.data) : reject(res);
        },
      });
    });
  };

  // 选择视频
  u.chooseVideo = function(opt = {}) {
    let option = JSON.parse(JSON.stringify(opt));
    option.count = option.max || 1;
    delete option.max;
    return new Promise((resolve, reject) => {
      xah.chooseVideo({
        ...option,
        complete: res => {
          res.code === 0 ? resolve(res.data) : reject(res);
        },
      });
    });
  };

  // 压缩视频
  u.compressorVideo = function(opt = {}) {
    return new Promise((resolve, reject) => {
      xah.compressorVideo({
        path: opt.path,
        quality: opt.quality,
        progress: opt.progress,
        complete: res => {
          res.code === 0 ? resolve(res.data) : reject(res);
        },
      });
    });
  };

  // 上传图片
  u.uploadImage = function(opt = {}) {
    return new Promise((resolve, reject) => {
      xah.uploadImage({
        ...opt,
        complete: res => {
          res.code === 0 ? resolve(res.data) : reject(res);
        },
      });
    });
  };

  // 上传视频
  u.uploadVideo = async function(opt = {}) {
    return new Promise((resolve, reject) => {
      xah.uploadVideo({
        ...opt,
        complete: res => {
          res.code === 0 ? resolve(res.data) : reject(res);
        },
      });
    });
  };

  // 上传语音
  u.uploadVoice = async function(opt = {}) {
    return new Promise((resolve, reject) => {
      xah.uploadVoice({
        ...opt,
        complete: res => {
          res.code === 0 ? resolve(res.data) : reject(res);
        },
      });
    });
  };

  // 通知取消压缩
  // u.cancelCompress = function (opt = {}) {
  //   return new Promise((resolve, reject) => {
  //     xah.cancelCompress({
  //       path: opt.path,
  //       params: opt.params,
  //       complete: res => {
  //         res.code === 0 ? resolve(res.data) : reject(res);
  //       },
  //     });
  //   });
  // };

  // 通知原生取消上传
  // u.cancelUpload = function (opt = {}) {
  //   return new Promise((resolve, reject) => {
  //     xah.cancelUpload({
  //       path: opt.path,
  //       params: opt.params,
  //       complete: res => {
  //         res.code === 0 ? resolve(res.data) : reject(res);
  //       },
  //     });
  //   });
  // };
}

xgjsdk = u;

export default xgjsdk;
