import Base from '../base/base.js'

import { getAliOSS } from './depend/get-libs.js';
import compress from './depend/compress.js';

/* {
  env: 环境，测试还是生产
  getStsParams: 找业务层获取sts的入参的方法，
  file: ,
  content: base64或blob数据
  progress: callback 上传进度回调
} */
class OssClass_H5 extends Base {
  constructor(opts = {}) {
    super(opts)

    this.content = opts.content
    this.abortCheckpoint = null
  }

  async initOssClient() {
    if (this.client) { return }
    await getAliOSS()
    let sts = this.sts
    this.client = new OSS({
      region: sts.region,
      accessKeyId: sts.accessKeyId,
      accessKeySecret: sts.accessKeySecret,
      stsToken: sts.securityToken,
      bucket: sts.bucketName,
      // 是否启用'https'
      secure: true,
      // 超时时间
      timeout: '300s',
    });
  }

  // 压缩
  async fileHandle() {
    let content = this.file
    if (this.fileTypeStr == 'image') {
      if (this.content) {
        content = this.content
      } else {
        content = await compress({
          file: this.file,
        });
      }
    }

    return content
  }

  // 上传普通文件
  async ossClientUpload() {
    let content = await this.fileHandle()

    let ret = await this.client.multipartUpload(this.ossFileKey, content, {
      checkpoint: this.abortCheckpoint,
      progress: (p, cpt, res) => {
        /* p: 上传进度， cpt：断点记录，也就是当前分片 */
        this.abortCheckpoint = cpt
        this.progress && this.progress(Math.floor(p * 100));
      },
      callback: {
        url: this.sts.callbackUrl,
        body: this.sts.webCallbackBody,
        contentType: 'application/json',
        // 自定义参数：
        customValue: this.customValue,
      },
    })

    return ret.data.data
  }

  // 启动上传
  async upload() {
    this._stop_ = false
    return new Promise(async (resolve, reject) => {
      await this.getSts()
      await this.initOssClient()
      if (!this._stop_) {
        let uploadResult = await this.ossClientUpload()
        this.success && this.success(uploadResult);
        resolve(uploadResult)
      }
    })
  }

  // 暂停
  async pause() {
    // 标识为暂停状态
    this._stop_ = true
    this.client && this.client.cancel();
  }

  // 恢复
  async resume() {
    this.upload()
  }
}



export default OssClass_H5;


